/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react';
import { Helmet } from 'react-helmet';

function NoIndex() {
  return (
    <Helmet
      meta={[
        {
          name: `robots`,
          content: `noindex`,
        },
      ]}
    />
  );
}

export default NoIndex;
