import React from 'react';

import { Container, Box, useTheme } from '@mui/material';

import { Left, Centered } from './logo';

const HeaderWithoutCallToAction = () => {
  const theme = useTheme();
  return (
    <Box
      component="header"
      sx={{
        position: 'sticky',
        top: 0,
        transition: theme.transitions.create('top'),
        zIndex: theme.zIndex.appBar,
        boxShadow: `inset 0px -1px 1px ${theme.palette.grey[400]}`,
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        backdropFilter: 'blur(20px)',
      }}
    >
      <Container
        maxWidth={false}
        sx={{
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: { xs: 'center', md: 'space-between' },
            alignItems: 'center',
            minHeight: '56px',
          }}
        >
          <Box sx={{ display: { xs: 'inline', md: 'none' } }}>
            <Centered
              title="Successful Grower Secrets App"
              style={{ width: '10rem' }}
            />
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'inline' } }}>
            <Left
              title="Successful Grower Secrets App"
              style={{ width: '10rem' }}
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default HeaderWithoutCallToAction;
