import React from 'react';
import PropTypes from 'prop-types';

import Footer from '../shared/footer';
import Header from '../shared/header-without-call-to-action';
import NoIndex from '../shared/no-index';
import LoadingScreen from '../shared/loading-screen';
import ClientOnly from '../shared/client-only';

import useKalansoPage from '../shared/use-kalanso-page';

import proxies from '../letters/proxies';

const ReferralLeadPage = ({ data }) => {
  const page = data.kalansoPage;

  const pageContent = useKalansoPage(page, 'content');
  const moduleName = pageContent.get('letter.module');
  const ReferralLetter = proxies[moduleName];

  return (
    <>
      <NoIndex />
      <ClientOnly ssr={<LoadingScreen />}>
        <React.Suspense fallback={<LoadingScreen />}>
          <Header page={page} />
          <ReferralLetter page={page} />
          <Footer page={page} />
        </React.Suspense>
      </ClientOnly>
    </>
  );
};

ReferralLeadPage.propTypes = {};

export default ReferralLeadPage;
