const useKalansoPage = (kalansoPage, property) => {
  const allProps = kalansoPage[property];

  const get = (name, key = 'key') => {
    let prop;

    if (Array.isArray(allProps)) {
      prop = allProps.find((prop) => prop[key] === name);
    } else {
      prop = allProps[key];
    }

    if (!prop) {
      console.warn(`Prop missing: ${name}`);
      return null;
    }

    if (prop.dataType === 'json') {
      return JSON.parse(prop.value);
    }

    if (typeof prop === 'object' && typeof prop.value !== 'undefined') {
      return prop.value;
    }

    return prop;
  };

  return { get };
};

export default useKalansoPage;
