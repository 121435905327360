import React from 'react';

const OrganicLeadDirectOfferLetter = React.lazy(() =>
  import(`./organic-lead-direct-offer-letter`),
);
const OrganicOptInThankYouLetter = React.lazy(() =>
  import(`./organic-opt-in-thank-you-letter`),
);
const ReferralLeadLetterJanaMilbocker = React.lazy(() =>
  import(`./referral-lead-letter-jana-milbocker`),
);
const ReferralLeadLetterPatWhite = React.lazy(() =>
  import(`./referral-lead-letter-pat-white`),
);

const salesLetterProxies = {
  OrganicLeadDirectOfferLetter,
  OrganicOptInThankYouLetter,
  ReferralLeadLetterJanaMilbocker,
  ReferralLeadLetterPatWhite,
};

export default salesLetterProxies;
