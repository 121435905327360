import React from 'react';

import { graphql } from 'gatsby';

import ReferralLeadPage from '../components/referral-lead/referral-lead-page';

export const query = graphql`
  query ReferralLeadPageQuery($pageId: String!, $breakpoints: [Int!]) {
    kalansoPage(pageId: { eq: $pageId }) {
      ...KalansoFullPageFragment
    }
  }
`;

const ReferralLeadTemplate = (props) => {
  return (
    <>
      <ReferralLeadPage {...props} />;
    </>
  );
};

export default ReferralLeadTemplate;
